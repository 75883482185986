import dayjs from 'dayjs'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const MediaIsImg = styled(Img)``

const MediaIsDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;
  max-width: 160px;
  flex: 0 0 auto;
  padding: 19px 0 14px 20px;
  text-align: center;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

const MonthDay = styled.p`
  margin-bottom: 8px;
  font-size: 60px; // Refactor to use theme variables
  line-height: 50px; // Refactor to use theme variables
  font-weight: 300;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 75px; // Refactor to use theme variables
    line-height: 75px; // Refactor to use theme variables
    margin-top: 6px; // Refactor to use theme variables
  }
`

const WeekDay = styled.p`
  font-size: 18px;
  margin-top: 0;
  line-height: 23px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  color: ${props => props.theme.colors.roseGold};

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 19px;
    letter-spacing: 3px;
  }
`

const DetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => (props.isCalendar ? '20px' : '15px 10px 15px 20px')};

  .headline--small {
    margin: ${props => (props.isCalendar ? null : '6px 0 10px')};
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    padding: 20px;
  }

  ${props =>
    props.isCalendar
      ? `.headline--secondary { margin-top: 0; margin-bottom: 17px; }`
      : null}
`

const Category = styled.p``

const Title = styled.h3`
  /* margin: 5px 0 16px 0; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: calc(${props => props.theme.typography.headlineMedium.lhSm} * 1);

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    -webkit-line-clamp: 2;
    max-height: calc(
      ${props => props.theme.typography.headlineMedium.lhLg} * 2
    );
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    -webkit-line-clamp: 3;
    max-height: calc(
      ${props => props.theme.typography.headlineMedium.lhLg} * 3
    );
  }
`

const Excerpt = styled.div`
  margin: 0 0 12px 0;
  line-height: ${props => props.theme.typography.body.lhSm};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  max-height: calc(${props => props.theme.typography.body.lhSm} * 4);

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    display: block;
    margin: 0 0 20px 0;
    line-height: ${props => props.theme.typography.body.lhLg};
    -webkit-line-clamp: 5;
    max-height: calc(${props => props.theme.typography.body.lhLg} * 5);
  }

  @media screen and (max-width: ${props => props.theme.responsive.underLarge}) {
    display: none;
  }
`

const MediaObjectItem = styled.li`
  grid-column: span 1;
  background-color: white;
  box-shadow: ${props => props.theme.shared.boxShadow};

  > a {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    text-decoration: none;
    color: inherit;
  }

  .gatsby-image-wrapper {
    width: 31vw;
    height: 31vw;
    flex-shrink: 0;
  }

  .headline--secondary {
    margin-top: ${props => (props.isCalendar ? '0' : '6px')};
    margin-bottom: ${props => (props.isCalendar ? '17px' : '12px')};
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    grid-column: span 4;

    .gatsby-image-wrapper {
      width: 100%;
      height: 230px;
    }

    ${Title} {
      max-height: calc(
        ${props => props.theme.typography.headlineMedium.lhLg} * 3
      );
    }
  }
`

const MediaObject = props => {
  return (
    <MediaObjectItem
      isCalendar={props.isCalendar}
      className={props.index === 0 ? 'post-one' : ''}
    >
      <Link title={props.title} to={`/${props.slug}/`}>
        {props.isCalendar && (
          <MediaIsDate>
            <div>
              <MonthDay>{dayjs(props.startDate).format('D')}</MonthDay>
              <WeekDay>
                {props.showMonth
                  ? dayjs(props.startDate).format('MMM')
                  : dayjs(props.startDate).format('ddd')}
              </WeekDay>
            </div>
          </MediaIsDate>
        )}
        {props.heroImage && (
          <MediaIsImg
            fluid={props.heroImage.fluid}
            backgroundColor={'#eeeeee'}
          />
        )}
        <DetailsWrapper isCalendar={props.isCalendar}>
          {props.category && (
            <Category className="label">{props.category}</Category>
          )}
          <Title className="headline--small" title={props.title}>
            {props.title}
          </Title>
          <Excerpt
            dangerouslySetInnerHTML={{
              __html: props.body,
            }}
          />
          {props.publishDate && <p className="detail">{props.publishDate}</p>}
          {props.isCalendar && (
            <p className="detail">
              {props.startTime && props.endTime
                ? dayjs(props.startTime).format('h:mm A')
                : 'All-Day Event'}
              {props.endTime && ` – ${dayjs(props.endTime).format('h:mm A')}`}
            </p>
          )}
        </DetailsWrapper>
      </Link>
    </MediaObjectItem>
  )
}

export default MediaObject
