import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
  }
`

const EventsList = props => {
  return <List>{props.children}</List>
}

export default EventsList
