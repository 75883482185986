import React from 'react'
import styled from 'styled-components'

const Title = styled.h3`
  text-align: ${props => (props.alignLeft ? 'left' : null)};
`

const SectionTitle = props => {
  return (
    <Title alignLeft={props.alignLeft} className="section-title">
      {props.title}
    </Title>
  )
}

export default SectionTitle
