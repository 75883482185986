import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import Card from '../components/Card'
import CardList from '../components/CardList'
import Container from '../components/Container'
import EventsList from '../components/EventsList'
import Layout from '../components/Layout'
import LinkAsButton from '../components/LinkAsButton'
import MediaObject from '../components/MediaObject'
import PageHeader from '../components/PageHeader'
import Section from '../components/Section'
import SectionTitle from '../components/SectionTitle'
import SEO from '../components/SEO'

const ImgAsBG = styled(Img)`
  position: absolute !important;
  width: 100%;
  height: 620px;
  background: transparent center center / cover no-repeat;

  @media (min-width: ${props => props.theme.responsive.medium}) {
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
  }

  picture > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`

const MissionSection = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 620px;
  background-color: #979c9e;
`

const MissionStatement = styled.div`
  position: relative;
  max-width: 305px;
  text-align: center;
  color: white;

  hr {
    width: 35px;
    height: 2px;
    margin: 25px auto 22px;
    background-color: currentcolor;
  }

  em {
    font-family: ${props => props.theme.typography.displayFamily};
    font-style: italic;
    font-size: 19px;
    line-height: 26px;
    color: white;
  }
`

const Index = ({ data }) => {
  const posts = data.allContentfulPost.edges
  const events = data.allContentfulEvent.edges
  const heroImg = data.allContentfulAsset.edges[0].node
  const MissionBGImg = data.allContentfulAsset.edges[0].node

  return (
    <Layout>
      <SEO />

      <PageHeader
        bgPosition="50% 10%"
        noParallax="true"
        image={heroImg}
        heightSm="460px"
        heightLg="700px"
        titleMarginTopSm="65px"
        titleMarginTopLg="96px"
        title="Have your cake and <span>eat it too!</span>"
      />
      <Container wrap="true">
        <Section>
          <SectionTitle title="News & Updates" />
          <CardList>
            {posts.map(({ node: post }, index) => (
              <Card key={post.id} index={index} {...post} />
            ))}
          </CardList>
          <LinkAsButton to="/news/" title="View More News" />
        </Section>
      </Container>
      <MissionSection>
        <ImgAsBG
          style={{ backgroundImage: `url(${MissionBGImg.fluid.src})` }}
          fluid={MissionBGImg.fluid}
        />
        <MissionStatement>
          <SectionTitle title="The Maskcara Mission" />
          <hr />
          <p>
            <em>
              Everything we do and everything we create is based on what we
              believe in. That helping others look beautiful is nice, but
              helping them believe they are beautiful is life changing.
              Happiness is contagious. And with enough courage, creativity, and
              optimism, you actually can have your cake and eat it too!
            </em>
          </p>
        </MissionStatement>
      </MissionSection>
      <Container wrap="true">
        <Section>
          <SectionTitle title="Upcoming Events" />
          <EventsList>
            {events.map(({ node: event }) => {
              return (
                <MediaObject
                  key={event.id}
                  isCalendar="true"
                  showMonth="true"
                  slug={`calendar/${event.slug}/`}
                  title={event.title}
                  body={event.body.childMarkdownRemark.excerpt}
                  startDate={event.startTime}
                  startTime={event.startTime}
                  endTime={event.endTime}
                />
              )
            })}
          </EventsList>
          <LinkAsButton to="/calendar/" title="More Events" />
        </Section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($postsLimit: Int, $eventsLimit: Int, $timeNow: Date) {
    allContentfulPost(
      sort: { fields: [featured, publishDate], order: [DESC, DESC] }
      limit: $postsLimit
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM D, YYYY")
          category {
            title
          }
          heroImage {
            title
            fluid(quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allContentfulEvent(
      filter: { startTime: { gt: $timeNow } }
      sort: { fields: [startTime], order: [ASC] }
      limit: $eventsLimit
    ) {
      edges {
        node {
          title
          id
          slug
          body {
            childMarkdownRemark {
              excerpt(pruneLength: 246)
            }
          }
          startTime
          endTime
          eventCategory {
            title
          }
        }
      }
    }
    allContentfulAsset(
      filter: { title: { eq: "Homepage Hero Image - Mobile" } }
    ) {
      edges {
        node {
          ...HeroImageSettings
        }
      }
    }
  }
`

export const HeroImageSettings = graphql`
  fragment HeroImageSettings on ContentfulAsset {
    fluid(sizes: "700px, (min-width: 768px) 1200px, 100vw", quality: 80) {
      ...GatsbyContentfulFluid_withWebp_noBase64
    }
  }
`

export default Index
