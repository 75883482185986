import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Button = styled(Link)`
  margin: 20px auto 0 !important;
  transform: translateX(
    calc(50vw - 50% - ${props => props.theme.spacing.siteXPadding})
  );
  text-decoration: none;
  cursor: pointer;
`

const LinkAsButton = ({ to, title, ...props }) => (
  <Button className="btn" to={to}>
    {title}
  </Button>
)

export default LinkAsButton
